<div class="container-flude d-none d-lg-block">
    <header>
        <div class="container-fluid">
            <!-- <div class="row" *ngIf="api.contactEnable">
                <div class="col-6 text-center">
                    <p>{{ api.description }}</p>
                </div>
                <div class="col-6 text-center">
                    <p class="phoneNo" *ngIf="api.contactEnable">{{ api.conatctNo }}</p>
                </div>
            </div> -->
            <div class="row">
                <div class="col-12">
                    <div class="infoAndRating">
                        <div class="info">
                            <div class="list" *ngIf="api.contactEnable">
                                <p *ngIf="api.contactEnable">{{ api.description }}</p>
                            </div>
                            <div class="list" *ngIf="api.contactEnable">
                                <p *ngIf="api.contactEnable">
                                    <a [href]="'tel:' + api.conatctNo">{{ api.conatctNo }}</a>
                                </p>
                            </div>
                        </div>
                        <div class="rating">
                            <div class="list">
                                <a class="google-ratting" href="https://bit.ly/3jYeT5K">
                                    <img src="assets/images/google-rating.png" alt="" />
                                </a>
                            </div>
                            <div class="list">
                                <a href="https://uk.trustpilot.com/review/elitemoving.co.uk">
                                    <img src="assets/images/trustpilot-rating.png" alt="" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg">
                        <div class="navbar-header">
                            <a href="index.html" class="logo-text"><img
                                    src="assets/images/Elite-Moving-DM-03a-Final-01.png" alt="" /></a>
                        </div>
                        <div class="collapse navbar-collapse nav-menu" id="navbarNavAltMarkup">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="#">Home</a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" (mouseenter)="openDropdown()" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false"> Instant Quote & Book</a>
                                    <ul class="dropdown-menu" *ngIf="isDropdownOpen">
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()" routerLink="/order"
                                                role="button" aria-expanded="false"> UK
                                                Removals
                                            </a>
                                            <!-- <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/european-removals-engine" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"> European Removals
                                            </a> -->
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()" routerLink="/storage"
                                                role="button" aria-expanded="false"> Storage
                                            </a>
                                            <!-- <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/european-removals-engine" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"> European Removals
                                            </a> -->
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a style="margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }"
                                                (click)="closeDropdown()" role="button" aria-expanded="false"> European
                                                Removals</a>
                                            <ul class="child-menu" [ngClass]="api.houseRemovals ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item" *ngFor="let service of europeanList"
                                                        (click)="closeDropdown();"
                                                        [routerLink]="['/er-service', encodeServiceType(service.type)]">{{
                                                        service.name
                                                        }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <!--
                                <div class="dropdown-container">
                                    <div class="dropdown">
                                        <h4 class="drop-text" (mouseenter)="lgSubOption = false">Services <i class="fa fa-angle-down"></i></h4>
                                        <div class="dropdown-content" [@collapseAnimation]="lgSubOption ? 'collapsed' : 'expanded'">
                                             <a class="sub-option" (click)="setLgSubOption()" routerLink="/man-&-van">Man & Van</a>
                                            <a class="sub-option" (click)="setLgSubOption()" routerLink="/european-removals">European Removals</a>
                                            <hr style="margin: 0" />
                                            <a class="sub-option" (click)="setLgSubOption()" routerLink="/house_removal">House Removal</a>
                                            <hr style="margin: 0" />
                                            <a class="sub-option" (click)="setLgSubOption()" routerLink="/packing-services">Packing Services</a>
                                            <hr style="margin: 0" />
                                            <a class="sub-option" (click)="setLgSubOption()" routerLink="/storage">Storage</a>
                                            <a class="sub-option" *ngFor="let service of api.servicesListEuropean" (click)="setLgSubOption(); selectService(service.id)" routerLink="/elite-services" [queryParams]="{id:service.id, type:service.type}">{{service.name}}</a>
                                            <hr style="margin: 0" />
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <li class="nav-item">
                                    <div class="btn-group" dropdown [autoClose]="true" autoOpencontainer="body">
                                        <a id="button-nested" dropdownToggle type="button" class="dropdown-toggle nav-link" aria-controls="dropdown-nested"> Services <span class="caret"></span> </a>
                                        <ul id="dropdown-nested" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-nested">


                                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                                <a dropdownToggle class="dropdown-item" [ngClass]="{ 'dropdown-toggle': api.servicesListMan.length > 0 }" routerLink="/man-&-van" (click)="(false)">Man & Van <span class="caret"></span></a>
                                                <ng-container *ngIf="api.servicesListMan.length > 0">
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                        <li role="menuitem" *ngFor="let service of api.servicesListMan">
                                                            <a class="dropdown-item" (click)="setLgSubOption(); selectService(service.id)" [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                                <a dropdownToggle class="dropdown-item" [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }" routerLink="/house_removal" (click)="(false)">House Removals <span class="caret"></span></a>
                                                <ng-container *ngIf="api.servicesListHouse.length > 0">
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                        <li role="menuitem" *ngFor="let service of api.servicesListHouse">
                                                            <a class="dropdown-item" (click)="setLgSubOption(); selectService(service.id)" [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                                <a dropdownToggle class="dropdown-item" [ngClass]="{ 'dropdown-toggle': api.servicesListEuropean.length > 0 }" routerLink="/european-removals" (click)="(false)">European Removals <span class="caret"></span></a>
                                                <ng-container *ngIf="api.servicesListEuropean.length > 0">
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                        <li role="menuitem" *ngFor="let service of api.servicesListEuropean">
                                                            <a class="dropdown-item" (click)="setLgSubOption(); selectService(service.id)" [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                                <a dropdownToggle class="dropdown-item" [ngClass]="{ 'dropdown-toggle': api.servicesListPacking.length > 0 }" routerLink="/packing-services" (click)="(false)">Packing Services <span class="caret"></span></a>
                                                <ng-container *ngIf="api.servicesListPacking.length > 0">
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                        <li role="menuitem" *ngFor="let service of api.servicesListPacking">
                                                            <a class="dropdown-item" (click)="setLgSubOption(); selectService(service.id)" [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                            <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
                                                <a dropdownToggle class="dropdown-item" [ngClass]="{ 'dropdown-toggle': api.servicesListStorage.length > 0 }" routerLink="/storage" (click)="(false)">Storage <span class="caret"></span></a>
                                                <ng-container *ngIf="api.servicesListStorage.length > 0">
                                                    <ul *dropdownMenu class="dropdown-menu" role="menu">
                                                        <li role="menuitem" *ngFor="let service of api.servicesListStorage">
                                                            <a class="dropdown-item" (click)="setLgSubOption(); selectService(service.id)" [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                        </li>
                                                    </ul>
                                                </ng-container>
                                            </li>
                                        </ul>
                                    </div>
                                </li> -->
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" (mouseenter)="openDropdown()" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false"> Services </a>
                                    <ul class="dropdown-menu" *ngIf="isDropdownOpen">
                                        <li class="nav-item dropend">
                                            <a style="margin-left: 0; margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListMan.length > 0 }"
                                                (click)="closeDropdown()" routerLink="/man-&-van" role="button"
                                                aria-expanded="false"> Man & Van </a>
                                            <ul class="child-menu" [ngClass]="api.manAndWan ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item" *ngFor="let service of api.servicesListMan"
                                                        (click)="closeDropdown(); selectService(service.type)"
                                                        [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a style="margin-left: 0; margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }"
                                                (click)="closeDropdown()" routerLink="/house_removal" role="button"
                                                aria-expanded="false"> House Removals </a>
                                            <ul class="child-menu" [ngClass]="api.houseRemovals ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item"
                                                        *ngFor="let service of api.servicesListHouse"
                                                        (click)="closeDropdown(); selectService(service.type)"
                                                        [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <!-- <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListEuropean.length > 0 }"
                                                (click)="closeDropdown()" routerLink="/european-removals" role="button"
                                                data-bs-toggle="dropdown" aria-expanded="false"> European Removals </a>
                                            <ul class="child-menu"
                                                [ngClass]="api.euorpeanRemovals ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item"
                                                        *ngFor="let service of api.servicesListEuropean"
                                                        (click)="closeDropdown(); selectService(service.type)"
                                                        [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                </li>
                                            </ul>
                                        </li> -->
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a style="margin-left: 0; margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListPacking.length > 0 }"
                                                (click)="closeDropdown()" routerLink="/packing-services" role="button"
                                                aria-expanded="false"> Packers & Movers </a>
                                            <ul class="child-menu"
                                                [ngClass]="api.pakingServices ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item"
                                                        *ngFor="let service of api.servicesListPacking"
                                                        (click)="closeDropdown(); selectService(service.type)"
                                                        [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a style="margin-left: 0; margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListStorage.length > 0 }"
                                                (click)="closeDropdown()" routerLink="/storage" role="button"
                                                aria-expanded="false"> Storage </a>
                                            <ul class="child-menu"
                                                [ngClass]="api.storageServices ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item"
                                                        *ngFor="let service of api.servicesListStorage"
                                                        (click)="closeDropdown(); selectService(service.type)"
                                                        [routerLink]="['/service', service.type]">{{ service.name }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" routerLink="/contact-us">Contact Us</a>
                                </li>
                                <li class="nav-item" *ngIf="hasTradePartner === true">
                                    <a class="nav-link" routerLink="/trade-partner/inquiry">Inquiries</a>
                           
                                <!-- <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" (mouseenter)="openDropdown()" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false"> Inquiries</a>
                                    <ul class="dropdown-menu" *ngIf="isDropdownOpen">
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()" routerLink="/trade-partner/inquiry"
                                                role="button" aria-expanded="false">
                                                Inquiries List
                                            </a>
                                       
                                        </li>
                                        <li>
                                            <hr class="dropdown-divider" />
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()" routerLink="/trade-partner/shared-enquiries"
                                                role="button" aria-expanded="false"> Shared Inquiries
                                            </a>
                                       
                                        </li>
                                    </ul>
                                </li> -->
                                <li class="nav-item dropdown" *ngIf="hasCustomer === true">
                                    <a class="nav-link dropdown-toggle" (mouseenter)="openDropdown()" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">Orders</a>
                                    <ul class="dropdown-menu" *ngIf="isDropdownOpen">
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/customer/orders-history" role="button"
                                                aria-expanded="false">UK Removals</a>
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/customer/storage-orders" role="button"
                                                aria-expanded="false">Storage</a>
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" style="white-space: nowrap;"
                                                routerLink="/customer/er-orders" (click)="closeDropdown()"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }"
                                                role="button" aria-expanded="false">European Removals</a>
                                        </li>
                                    </ul>
                                </li>

                                <!-- <li class="nav-item">
                                    <a class="nav-link" href="#">Services</a>
                                </li> -->

                                <!-- <li class="nav-item">
                                    <a class="nav-link" (click)="scrollToElement('#about-us')">About Us</a>
                                </li> -->

                                <!--<li><a class="nav-link " routerLink="/privacy-policy">Privacy Policy</a>
                                <li (click)="openFile()"><a href="">Terms & Conditions</a></li>-->
                                <!-- <li><a class="nav-link " routerLink="/Terms-&-Conditions">Terms & Conditions</a> -->

                                <li class="nav-item" *ngIf="!isAdmin && !isCustomer&& !isTradePartner ">
                                    <a class="nav-link" routerLink="/login">Login</a>
                                </li>
                                <li *ngIf="isAdmin || isCustomer || isTradePartner" class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);" (click)="logOut()">Logout</a>
                                </li>

                                <li class="nav-item d-lg-none" *ngIf="!isAdmin && !isCustomer && !isTradePartner">
                                    <a class="nav-link" routerLink="/registration">Sign Up</a>
                                </li>
                            </ul>
                            <button *ngIf="!isAdmin && !isCustomer && !isTradePartner"
                                class="sign-up-btn d-lg-flex d-none" type="button"><a routerLink="/registration">Sign
                                    Up</a></button>

                            <button *ngIf="isAdmin" class="sign-up-btn d-lg-flex d-none" type="button">
                                <a routerLink="/admin/dashboard">My Portal</a>
                            </button>
                            <button *ngIf="isCustomer" class="sign-up-btn d-lg-flex d-none" type="button">
                                <a routerLink="/customer/orders-history">My Portal</a>
                            </button>
                            <button *ngIf="isTradePartner" class="sign-up-btn d-lg-flex d-none" type="button">
                                <a routerLink="/trade-partner/orders-history">My Portal</a>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</div>

<!-- for mobile and Tab -->
<div class="container-flude d-lg-none">
    <header>
        <div class="container-fluid">
            <div class="infoAndRating">
                <div class="info">
                    <div class="list" *ngIf="api.contactEnable">
                        <p *ngIf="api.contactEnable">{{ api.description }}</p>
                    </div>
                    <div class="list" *ngIf="api.contactEnable">
                        <p *ngIf="api.contactEnable">
                            <a [href]="'tel:' + api.conatctNo">{{ api.conatctNo }}</a>
                        </p>
                    </div>
                </div>
                <div class="rating">
                    <div class="list">
                        <a class="google-ratting" href="https://bit.ly/3jYeT5K">
                            <img src="assets/images/google-rating.png" alt="" />
                        </a>
                    </div>
                    <div class="list">
                        <a href="https://uk.trustpilot.com/review/elitemoving.co.uk">
                            <img src="assets/images/trustpilot-rating.png" alt="" />
                        </a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <nav class="navbar navbar-expand-lg">
                        <div class="navbar-header">
                            <a href="index.html" class="logo-text"><img
                                    src="assets/images/Elite-Moving-DM-03a-Final-01.png" alt="" /></a>
                            <button class="navbar-toggler" type="button" (click)="setCollapsed()">
                                <div id="navicon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                        </div>
                        <div class="collapse navbar-collapse nav-menu"
                            [@collapseAnimation]="isCollapsed ? 'collapsed' : 'expanded'"
                            [ngClass]="{ collapse: isCollapsed }">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" href="#" (click)="setCollapsed()">Home</a>
                                </li>
                                <li class="nav-item">
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <h4 class="drop-text" (click)="setSubOption()">Instant Quote & Book <i
                                                    class="fa fa-angle-down"></i></h4>

                                            <div class="dropdown-content"
                                                [@collapseAnimation]="subOption ? 'collapsed' : 'expanded'"
                                                [ngClass]="{ collapse: isCollapsed }">
                                                <a class="sub-option" routerLink="/order">UK Removals </a>
                                                <a class="sub-option" routerLink="/storage">Storage</a>
                                                <!-- <a class="sub-option" routerLink="/european-removals-engine">European
                                                    Removals</a> 

                                                    <li class="nav-item dropend">
                                            <a style="margin-left: 0; margin-right: 5px;" class="nav-link"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }"
                                                (click)="closeDropdown()" role="button" aria-expanded="false"> European
                                                Removals <i style="font-size:13px;" class="fa">&#xf0da;</i></a>
                                            <ul class="child-menu" [ngClass]="api.houseRemovals ? 'onTrue' : 'onFalse'">
                                                <li>
                                                    <a class="dropdown-item" *ngFor="let service of europeanList"
                                                        (click)="closeDropdown();"
                                                        [routerLink]="['/er-service', encodeServiceType(service.type)]">{{
                                                        service.name
                                                        }}</a>
                                                </li>
                                            </ul>
                                        </li>
                                                -->
                                                <div class="dropdown" (click)="toggleEuro()">
                                                    <a class="sub-option"><span>European
                                                            Removals</span><i class="fa fa-angle-down"
                                                            style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isEuroExpanded">
                                                        <a class="sub-sub-option" *ngFor="let service of europeanList"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/er-service', encodeServiceType(service.type)]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                        <!-- <a class="sub-sub-option" routerLink="/european-removals/sub-option1" (click)="setCollapsed()">Sub Option 1</a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="nav-item">
                                    <div class="dropdown-container">
                                        <div class="dropdown">
                                            <h4 class="drop-text" (click)="setSubOption()">Services <i
                                                    class="fa fa-angle-down"></i></h4>

                                            <div class="dropdown-content"
                                                [@collapseAnimation]="subOption ? 'collapsed' : 'expanded'"
                                                [ngClass]="{ collapse: isCollapsed }">
                                                <div class="dropdown" (click)="toggleMan()">
                                                    <a class="sub-option"><span routerLink="/man-&-van">Man & Van</span>
                                                        <i class="fa fa-angle-down" style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isManExpanded">
                                                        <a class="sub-sub-option"
                                                            *ngFor="let service of api.servicesListMan"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/service', service.type]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                        <!-- <a class="sub-sub-option" routerLink="/european-removals/sub-option1" (click)="setCollapsed()">Sub Option 1</a> -->
                                                    </div>
                                                </div>
                                                <!-- <hr style="margin: 0" />
                                                <div class="dropdown" (click)="toggleEuropeanRemovals()">
                                                    <a class="sub-option"><span routerLink="/european-removals">European
                                                            Removals</span> <i class="fa fa-angle-down"
                                                            style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isEuropeanRemovalsExpanded">
                                                        <a class="sub-sub-option"
                                                            *ngFor="let service of api.servicesListEuropean"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/service', service.type]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                    </div>
                                                </div> -->
                                                <!-- <a class="sub-option" routerLink="/european-removals" (click)="setCollapsed()">European Removals</a> -->

                                                <hr style="margin: 0" />
                                                <div class="dropdown" (click)="toggleHouse()">
                                                    <a class="sub-option"><span routerLink="/house_removal">House
                                                            Removals</span> <i class="fa fa-angle-down"
                                                            style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isHouseExpanded">
                                                        <a class="sub-sub-option"
                                                            *ngFor="let service of api.servicesListHouse"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/service', service.type]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                        <!-- <a class="sub-sub-option" routerLink="/european-removals/sub-option1" (click)="setCollapsed()">Sub Option 1</a> -->
                                                    </div>
                                                </div>
                                                <!-- <a class="sub-option" routerLink="/house_removal"
                                                    (click)="setCollapsed()">House Removal</a> -->
                                                <hr style="margin: 0" />
                                                <div class="dropdown" (click)="togglePacking()">
                                                    <a class="sub-option"><span routerLink="/packing-services">Packers &
                                                            Movers</span><i class="fa fa-angle-down"
                                                            style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isPackingExpanded">
                                                        <a class="sub-sub-option"
                                                            *ngFor="let service of api.servicesListPacking"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/service', service.type]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                        <!-- <a class="sub-sub-option" routerLink="/european-removals/sub-option1" (click)="setCollapsed()">Sub Option 1</a> -->
                                                    </div>
                                                </div>
                                                <!-- <a class="sub-option" routerLink="/packing-services"
                                                    (click)="setCollapsed()">Packing Services</a> -->
                                                <hr style="margin: 0" />
                                                <div class="dropdown" (click)="toggleStorage()">
                                                    <a class="sub-option"><span routerLink="/storage">Storage</span> <i
                                                            class="fa fa-angle-down" style="margin-left: 5px"></i></a>
                                                    <div class="dropdown-content" *ngIf="isStorageExpanded">
                                                        <a class="sub-sub-option"
                                                            *ngFor="let service of api.servicesListStorage"
                                                            (click)="setCollapsed(); selectService(service.type)"
                                                            [routerLink]="['/service', service.type]"><span
                                                                style="display: flex; align-items: center; margin-left: 10px">{{
                                                                service.name }}</span></a>
                                                        <!-- <a class="sub-sub-option" routerLink="/european-removals/sub-option1" (click)="setCollapsed()">Sub Option 1</a> -->
                                                    </div>
                                                </div>
                                                <!-- <a class="sub-option" routerLink="/storage"
                                                    (click)="setCollapsed()">Storage</a> -->
                                            </div>
                                        </div>
                                    </div>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" routerLink="/areas-covered" (click)="setCollapsed()">Areas</a>
                                </li>

                                <li class="nav-item">
                                    <a class="nav-link" routerLink="/contact-us" (click)="setCollapsed()">Contact Us</a>
                                </li>

                                <li class="nav-item dropdown" *ngIf="hasCustomer === true">
                                    <a class="nav-link dropdown-toggle" (mouseenter)="openDropdown()" role="button"
                                        data-bs-toggle="dropdown" aria-expanded="false">Orders</a>
                                    <ul class="dropdown-menu" *ngIf="isDropdownOpen">
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/customer/orders-history" role="button"
                                                aria-expanded="false">UK Removals</a>
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" (click)="closeDropdown()"
                                                routerLink="/customer/storage-orders" role="button"
                                                aria-expanded="false">Storage</a>
                                        </li>
                                        <li class="nav-item dropend">
                                            <a class="nav-link" style="white-space: nowrap;"
                                                routerLink="/customer/er-orders" (click)="closeDropdown()"
                                                [ngClass]="{ 'dropdown-toggle': api.servicesListHouse.length > 0 }"
                                                role="button" aria-expanded="false">European Removals</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item" *ngIf="!isAdmin && !isCustomer">
                                    <a class="nav-link" routerLink="/login" (click)="setCollapsed()">Login</a>
                                </li>

                                <li *ngIf="isAdmin || isCustomer" class="nav-item">
                                    <a class="nav-link" href="javascript: void(0);"
                                        (click)="logOut(); setCollapsed()">Logout</a>
                                </li>

                                <li class="nav-item d-lg-none" *ngIf="!isAdmin && !isCustomer">
                                    <a class="nav-link" routerLink="/registration" (click)="setCollapsed()">Sign Up</a>
                                </li>
                            </ul>
                            <button *ngIf="!isAdmin && !isCustomer" class="sign-up-btn d-lg-flex d-none"
                                type="button"><a routerLink="/registration" (click)="setCollapsed()">Sign
                                    Up</a></button>

                            <button *ngIf="isAdmin" class="sign-up-btn d-lg-flex d-none" type="button">
                                <a routerLink="/admin/dashboard">My Portal</a>
                            </button>
                            <button *ngIf="isCustomer" class="sign-up-btn d-lg-flex d-none" type="button">
                                <a routerLink="/customer/orders-history">My Portal</a>
                            </button>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
</div>